import React from "react";
import { EntityTypeName, Language } from "@shapeable/types";
import { AppRouteEntityType } from "@shapeable/ui";

type EntityTypeProps = {
  children: React.ReactNode;
  pageContext: {
    entityTypeName: EntityTypeName;
  };
};

export default ({ children, pageContext }: EntityTypeProps) => { return <AppRouteEntityType entityTypeName={pageContext?.entityTypeName}>{children}</AppRouteEntityType>; }
